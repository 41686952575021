<template>
  <div class="page-callback">
    <div class="title-callback">
      <pageTitle pageTitle="回调设置">
        <span v-if="isShowTip" class="title-callback-tip">
          未配置回调时，默认按照主账号回调设置进行事件推送
        </span>
      </pageTitle>
      <a href="https://saas-doc.vhall.com/opendocs/show/1163" target="_blank">回调设置说明</a>
    </div>
    <div class="layout-callback">
      <section class="cate_card">
        <h3 class="cate_name">基本设置</h3>
        <div class="cate_content">
          <vh-form :model="form" ref="form" :rules="formRules" label-width="102px">
            <vh-form-item label="签名Key" prop="secret_key">
              <vh-input
                v-model.trim="form.secret_key"
                v-clearEmoij
                autocomplete="off"
                placeholder="请输入签名规则"
                :maxlength="32"
                show-word-limit
              ></vh-input>
            </vh-form-item>
            <vh-form-item label="回调地址" prop="callback_url">
              <vh-input
                v-model.trim="form.callback_url"
                v-clearEmoij
                autocomplete="off"
                placeholder="请输入Https或http开头的完整url"
                :maxlength="255"
                show-word-limit
              ></vh-input>
            </vh-form-item>
            <vh-form-item label="消息格式" prop="msg_type" class="radio-btn" label-width="102px">
              <vh-radio-group v-model="form.msg_type">
                <vh-radio :label="1">application/x-www-form-urlencoded</vh-radio>
                <vh-radio :label="2">application/json</vh-radio>
              </vh-radio-group>
            </vh-form-item>
          </vh-form>
          <div class="div__view">
            <ul class="switch__list">
              <li class="switch__box">
                <label class="leve3_title label__r12">失败重试</label>
                <vh-switch
                  v-model="form.fail_try_request"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#FB3A32"
                  inactive-color="#CECECE"
                ></vh-switch>
                <span class="leve3_title title--999">
                  {{
                    !!form.fail_try_request
                      ? '已开启，系统需在5秒内响应SUCCESS（不区分大小写）'
                      : '开启后，系统需在5秒内响应SUCCESS（不区分大小写）'
                  }}
                </span>
                &nbsp;
                <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                  <div slot="content">
                    <span>1.默认关闭</span>
                    <br />
                    <p style="width: 400px">
                      2.开启后需要在5s内响应SUCCESS（不区分大小写），则视为投递成功，否则按下列规则重试：重试队列，重试16次，间隔时间为：10秒、30秒、1-10分钟、20分钟、30分钟、1小时、2小时
                    </p>
                  </div>
                  <i class="iconfont-v3 saasicon_help_m"></i>
                </vh-tooltip>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="cate_card" v-for="(item, index) in keyList" :key="index">
        <h3 class="cate_name">{{ item.title }}</h3>
        <div class="cate_content div__view">
          <ul class="switch__list">
            <li class="switch__box" v-for="(el, ins) in item.list" :key="`view_` + ins">
              <label class="leve3_title label__r12">{{ el.key_name }}</label>
              <vh-switch
                v-model="el.value"
                :active-value="1"
                :inactive-value="0"
                active-color="#FB3A32"
                inactive-color="#CECECE"
              ></vh-switch>
              <span class="leve3_title title--999">
                {{ !!el.value ? el.closeShow : el.openShow }}
              </span>
            </li>
          </ul>
        </div>
      </section>
      <vh-form label-width="91px">
        <vh-form-item label="" class="callback-btn">
          <vh-button
            type="primary"
            class="length152"
            v-preventReClick
            round
            @click.prevent.stop="saveCallbackInfo"
          >
            保 存
          </vh-button>
        </vh-form-item>
      </vh-form>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import { sessionOrLocal } from '@/utils/utils';
  export default {
    name: 'callback.vue',
    components: {
      PageTitle
    },
    data() {
      return {
        callbackVo: {},
        isAdd: null,
        form: {
          secret_key: null,
          callback_url: null,
          msg_type: null,
          fail_try_request: 0
        },
        formRules: {
          secret_key: [{ required: true, message: '请输入签名规则', trigger: 'blur' }],
          callback_url: [
            { required: true, message: '请输入Https或http开头的完整url', trigger: 'blur' },
            {
              pattern: /(http|https):\/\/[\w\-_]+(\.[\w\-_]+).*?/,
              message: '请输入Https或http开头的完整url',
              trigger: 'blur'
            }
          ],
          msg_type: [{ required: true, message: '请选择消息格式', trigger: 'blur' }]
        },
        keyList: [],
        isShowTip: false // 是否显示 回调文本 tip
      };
    },
    created() {
      this.userId = this.$route.query.userId;
      this.getCallbackInfo();
      // 设置是否显示 回调文本 tip
      this.setIsShowTip();
    },
    methods: {
      getCallbackInfo() {
        let eventsList = [];
        this.$fetch('getCallbackInfo', {})
          .then(res => {
            if (res && res.code === 200 && res.data) {
              // 若无内容，默认展示
              if (res.data && !res.data.msg_type) {
                res.data.msg_type = 1;
              }
              if (res.data && !res.data.fail_try_request) {
                res.data.fail_try_request = 0;
              }
              this.form = res.data;
              // this.form.callback_type = 1;
              eventsList = (res.data?.callback_event || '').split(',');
            } else {
              this.form = {
                secret_key: null,
                callback_url: null
              };
            }
            this.isAdd = !(res.data && res.data.id);
            let keyList = [
              {
                title: '活动信息',
                list: [
                  {
                    type: 'key_10',
                    key_name: '活动删除',
                    openShow: '开启后，直播活动删除成功进行通知',
                    closeShow: '已开启，直播活动删除成功进行通知',
                    value: Number(eventsList.includes('10') ? 1 : 0) || 0,
                    k: 100841
                  }
                ]
              },
              {
                title: '活动状态',
                list: [
                  {
                    type: 'key_1',
                    key_name: '活动状态',
                    openShow: '开启后，直播开始或结束时进行通知',
                    closeShow: '已开启，直播开始或结束时进行通知',
                    value: Number(eventsList.includes('1') ? 1 : 0) || 0,
                    k: 100601
                  },
                  {
                    type: 'key_14',
                    key_name: '活动推流状态',
                    openShow: '开启后，直播活动开始/结束推流时进行通知',
                    closeShow: '已开启，直播活动开始/结束推流时进行通知',
                    value: Number(eventsList.includes('14') ? 1 : 0) || 0,
                    k: 100852
                  },
                  {
                    type: 'key_13',
                    key_name: '活动状态改变',
                    openShow: '开启后，直播活动开播/结束/回放时进行通知',
                    closeShow: '已开启，直播活动开播/结束/回放时进行通知',
                    value: Number(eventsList.includes('13') ? 1 : 0) || 0,
                    k: 100850
                  },
                  {
                    type: 'key_11',
                    key_name: '恢复预告',
                    openShow: '开启后，直播活动恢复预告状态成功进行通知',
                    closeShow: '开启后，直播活动恢复预告状态成功进行通知',
                    value: Number(eventsList.includes('11') ? 1 : 0) || 0,
                    k: 100843
                  }
                ]
              },
              {
                title: '回放',
                list: [
                  {
                    type: 'key_4',
                    key_name: '生成回放',
                    openShow: '开启后，直播结束并生成回放成功进行通知',
                    closeShow: '已开启，直播结束并生成回放成功进行通知',
                    value: Number(eventsList.includes('4') ? 1 : 0) || 0,
                    k: 100603
                  },
                  {
                    type: 'key_8',
                    key_name: '裁剪回放',
                    openShow: '开启后，裁剪视频成功后进行通知',
                    closeShow: '已开启，裁剪视频成功后进行通知',
                    value: Number(eventsList.includes('8') ? 1 : 0) || 0,
                    k: 100605
                  },
                  {
                    type: 'key_5',
                    key_name: '回放分辨率',
                    openShow: '开启后，回放转码成功后支持获取不同分辨率',
                    closeShow: '已开启，回放转码成功后支持获取不同分辨率',
                    value: Number(eventsList.includes('5') ? 1 : 0) || 0,
                    k: 100607
                  },
                  {
                    type: 'key_6',
                    key_name: '回放下载',
                    openShow: '开启后，回放下载成功进行通知',
                    closeShow: '已开启，回放下载成功进行通知',
                    value: Number(eventsList.includes('6') ? 1 : 0) || 0,
                    k: 100609
                  },
                  {
                    type: 'key_9',
                    key_name: '回放重制',
                    openShow: '开启后，回放进行文档合并重制成功后进行通知',
                    closeShow: '已开启，回放进行文档合并重制成功后进行通知',
                    value: Number(eventsList.includes('9') ? 1 : 0) || 0,
                    k: 100848
                  }
                ]
              },
              {
                title: '媒资',
                list: [
                  {
                    type: 'key_3',
                    key_name: '视频转码',
                    openShow: '开启后，JSSDK上传视频并转码成功进行通知',
                    closeShow: '已开启，JSSDK上传视频并转码成功进行通知',
                    value: Number(eventsList.includes('3') ? 1 : 0) || 0,
                    k: 100611
                  },
                  {
                    type: 'key_7',
                    key_name: '文档转码',
                    openShow: '开启后，文档上传并转码成功进行通知',
                    closeShow: '已开启，文档上传并转码成功进行通知',
                    value: Number(eventsList.includes('7') ? 1 : 0) || 0,
                    k: 100613
                  }
                ]
              },
              {
                title: '订单',
                list: [
                  {
                    type: 'key_15',
                    key_name: '订单状态变化',
                    openShow: '开启后，直播中商品订单状态发生变更时进行通知',
                    closeShow: '已开启，直播中商品订单状态发生变更时进行通知',
                    value: Number(eventsList.includes('15') ? 1 : 0) || 0,
                    k: 100854
                  }
                ]
              },
              {
                title: 'AI创作',
                list: [
                  {
                    type: 'key_20',
                    key_name: '智能解析',
                    openShow: '开启后，智能解析创作完成进行通知',
                    closeShow: '已开启，智能解析创作完成进行通知',
                    value: Number(eventsList.includes('20') ? 1 : 0) || 0,
                    k: 100860
                  },
                  {
                    type: 'key_21',
                    key_name: '精彩时刻',
                    openShow: '开启后，精彩时刻创作完成进行通知',
                    closeShow: '已开启，精彩时刻创作完成进行通知',
                    value: Number(eventsList.includes('21') ? 1 : 0) || 0,
                    k: 100861
                  },
                  {
                    type: 'key_22',
                    key_name: '精彩剪辑',
                    openShow: '开启后，精彩剪辑创作完成进行通知',
                    closeShow: '已开启，精彩剪辑创作完成进行通知',
                    value: Number(eventsList.includes('22') ? 1 : 0) || 0,
                    k: 100862
                  },
                  {
                    type: 'key_23',
                    key_name: '新增精彩剪辑',
                    openShow: '开启后，新增精彩剪辑完成进行通知',
                    closeShow: '已开启，新增精彩剪辑完成进行通知',
                    value: Number(eventsList.includes('23') ? 1 : 0) || 0,
                    k: 100863
                  },
                  {
                    type: 'key_24',
                    key_name: '精彩剪辑下载',
                    openShow: '开启后，精彩剪辑下载打包完成进行通知',
                    closeShow: '已开启，精彩剪辑下载打包完成进行通知',
                    value: Number(eventsList.includes('24') ? 1 : 0) || 0,
                    k: 100864
                  },
                  {
                    type: 'key_25',
                    key_name: '概要总结',
                    openShow: '开启后，概要总结创作完成进行通知',
                    closeShow: '已开启，概要总结创作完成进行通知',
                    value: Number(eventsList.includes('25') ? 1 : 0) || 0,
                    k: 100865
                  }
                ]
              },
              {
                title: '报名',
                list: [
                  {
                    type: 'key_30',
                    key_name: '报名表单提交',
                    openShow: '开启后，报名表单提交后将观众和活动信息通知到第三方',
                    closeShow: '已开启，报名表单提交后将观众和活动信息通知到第三方',
                    value: Number(eventsList.includes('30') ? 1 : 0) || 0,
                    k: 100866
                  },
                ]
              },
              {
                title: '抽奖',
                list: [
                  {
                    type: 'key_35',
                    key_name: '中奖结果',
                    openShow: '开启后，抽奖结束后将观众和奖品信息通知到第三方',
                    closeShow: '已开启，抽奖结束后将观众和奖品信息通知到第三方',
                    value: Number(eventsList.includes('35') ? 1 : 0) || 0,
                    k: 100867
                  }
                ]
              }
            ];
            this.keyList = keyList;
          })
          .catch(e => {
            console.log(e);
            this.form = {
              secret_key: null,
              callback_url: null
            };
            this.keyList = [
              {
                title: '活动信息',
                list: [
                  {
                    type: 'key_10',
                    key_name: '活动删除',
                    openShow: '开启后，直播活动删除成功进行通知',
                    closeShow: '已开启，直播活动删除成功进行通知',
                    value: 0
                  }
                ]
              },
              {
                title: '活动状态',
                list: [
                  {
                    type: 'key_1',
                    key_name: '活动状态',
                    openShow: '开启后，直播开始或结束时进行通知',
                    closeShow: '已开启，直播开始或结束时进行通知',
                    value: 0
                  },
                  {
                    type: 'key_14',
                    key_name: '活动推流状态',
                    openShow: '开启后，直播活动开始/结束推流时进行通知',
                    closeShow: '已开启，直播活动开始/结束推流时进行通知',
                    value: 0
                  },
                  {
                    type: 'key_13',
                    key_name: '活动状态改变',
                    openShow: '开启后，直播活动开播/结束/回放时进行通知',
                    closeShow: '已开启，直播活动开播/结束/回放时进行通知',
                    value: 0
                  },
                  {
                    type: 'key_11',
                    key_name: '恢复预告',
                    openShow: '开启后，直播活动恢复预告状态成功进行通知',
                    closeShow: '开启后，直播活动恢复预告状态成功进行通知',
                    value: 0
                  }
                ]
              },
              {
                title: '回放',
                list: [
                  {
                    type: 'key_4',
                    key_name: '生成回放',
                    openShow: '开启后，直播结束并生成回放成功进行通知',
                    closeShow: '已开启，直播结束并生成回放成功进行通知',
                    value: 0
                  },
                  {
                    type: 'key_8',
                    key_name: '裁剪回放',
                    openShow: '开启后，裁剪视频成功后进行通知',
                    closeShow: '已开启，裁剪视频成功后进行通知',
                    value: 0
                  },
                  {
                    type: 'key_5',
                    key_name: '回放分辨率',
                    openShow: '开启后，回放转码成功后支持获取不同分辨率',
                    closeShow: '已开启，回放转码成功后支持获取不同分辨率',
                    value: 0
                  },
                  {
                    type: 'key_6',
                    key_name: '回放下载',
                    openShow: '开启后，回放下载成功进行通知',
                    closeShow: '已开启，回放下载成功进行通知',
                    value: 0
                  },
                  {
                    type: 'key_9',
                    key_name: '回放重制',
                    openShow: '开启后，回放进行文档合并重制成功后进行通知',
                    closeShow: '已开启，回放进行文档合并重制成功后进行通知',
                    value: 0
                  }
                ]
              },
              {
                title: '媒资',
                list: [
                  {
                    type: 'key_3',
                    key_name: '视频转码',
                    openShow: '开启后，JSSDK上传视频并转码成功进行通知',
                    closeShow: '已开启，JSSDK上传视频并转码成功进行通知',
                    value: 0
                  },
                  {
                    type: 'key_7',
                    key_name: '文档转码',
                    openShow: '开启后，文档上传并转码成功进行通知',
                    closeShow: '已开启，文档上传并转码成功进行通知',
                    value: 0
                  }
                ]
              },
              {
                title: '订单',
                list: [
                  {
                    type: 'key_15',
                    key_name: '订单状态变化',
                    openShow: '开启后，直播中商品订单状态发生变更时进行通知',
                    closeShow: '已开启，直播中商品订单状态发生变更时进行通知',
                    value: 0
                  }
                ]
              },
              {
                title: 'AI创作',
                list: [
                  {
                    type: 'key_20',
                    key_name: '智能解析',
                    openShow: '开启后，智能解析创作完成进行通知',
                    closeShow: '已开启，智能解析创作完成进行通知',
                    value: 0
                  },
                  {
                    type: 'key_21',
                    key_name: '精彩时刻',
                    openShow: '开启后，精彩时刻创作完成进行通知',
                    closeShow: '已开启，精彩时刻创作完成进行通知',
                    value: 0
                  },
                  {
                    type: 'key_22',
                    key_name: '精彩剪辑',
                    openShow: '开启后，精彩剪辑创作完成进行通知',
                    closeShow: '已开启，精彩剪辑创作完成进行通知',
                    value: 0
                  },
                  {
                    type: 'key_23',
                    key_name: '新增精彩剪辑',
                    openShow: '开启后，新增精彩剪辑完成进行通知',
                    closeShow: '已开启，新增精彩剪辑完成进行通知',
                    value: 0
                  },
                  {
                    type: 'key_24',
                    key_name: '精彩剪辑下载',
                    openShow: '开启后，精彩剪辑下载打包完成进行通知',
                    closeShow: '已开启，精彩剪辑下载打包完成进行通知',
                    value: 0
                  },
                  {
                    type: 'key_25',
                    key_name: '概要总结',
                    openShow: '开启后，概要总结创作完成进行通知',
                    closeShow: '已开启，概要总结创作完成进行通知',
                    value: 0
                  }
                ]
              },
              {
                title: '报名',
                list: [
                  {
                    type: 'key_30',
                    key_name: '报名表单提交',
                    openShow: '开启后，报名表单提交后将观众和活动信息通知到第三方',
                    closeShow: '已开启，报名表单提交后将观众和活动信息通知到第三方',
                    value: 0
                  },
                ]
              },
              {
                title: '抽奖',
                list: [
                  {
                    type: 'key_35',
                    key_name: '中奖结果',
                    openShow: '开启后，抽奖结束后将观众和奖品信息通知到第三方',
                    closeShow: '已开启，抽奖结束后将观众和奖品信息通知到第三方',
                    value: 0,
                    k: 100867
                  }
                ]
              }
            ];
          });
      },
      saveCallbackInfo() {
        console.log(this.keyList);
        let numKeys = [];
        this.keyList.forEach(item => {
          item.list.forEach(el => {
            if (Number(el.value) === 1) {
              numKeys.push(el.type.substring(4));
            }
          });
        });
        this.$refs.form.validate(valid => {
          if (valid) {
            let callback_event = '';
            let params = {
              secret_key: this.form.secret_key,
              callback_url: this.form.callback_url,
              callback_event: numKeys.join(','),
              fail_try_request: this.form.fail_try_request,
              msg_type: this.form.msg_type
            };
            this.$fetch(this.isAdd ? 'addCallbackInfo' : 'editCallbackInfo', params)
              .then(res => {
                this.setReportData(this.keyList);
                this.$vhMessage({
                  message: `设置成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                // 数据刷新
                this.getCallbackInfo();
              })
              .catch(res => {
                this.$vhMessage({
                  message: res.msg || `设置失败`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          }
        });
      },
      setReportData(list) {
        this.$vhall_paas_port({
          k: 100596,
          data: {
            business_uid: this.userId,
            user_id: '',
            s: '',
            webinar_id: '',
            refer: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.$vhall_paas_port({
          k: this.form.msg_type == 1 ? 100597 : 100598,
          data: {
            business_uid: this.userId,
            user_id: '',
            s: '',
            webinar_id: '',
            refer: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.$vhall_paas_port({
          k: this.form.fail_try_request == 1 ? 100599 : 100600,
          data: {
            business_uid: this.userId,
            user_id: '',
            s: '',
            webinar_id: '',
            refer: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        list.map(item => {
          this.$vhall_paas_port({
            k: item.value == 1 ? item.k : item.k + 1,
            data: {
              business_uid: this.userId,
              user_id: '',
              s: '',
              webinar_id: '',
              refer: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        });
      },
      // 设置是否展示提示
      setIsShowTip() {
        const userInfo = JSON.parse(sessionOrLocal.get('userInfo'));
        console.log('userInfo>>>>', userInfo);
        // 子账号
        if (userInfo.parent_id > 0) {
          this.isShowTip = true;
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .title-callback {
    &-tip {
      font-size: 14px;
      color: #999;
    }
    .titleBox {
      float: left;
    }
    a {
      float: right;
      font-size: 14px;
      font-weight: 400;
      color: #3562fa;
      line-height: 20px;
    }
  }
  .layout-callback {
    clear: both;
    .vh-form {
      ::v-deep .vh-input {
        width: 640px;
      }
      ::v-deep .vh-form-item {
        margin-bottom: 24px;
        &.callback-btn {
          margin-bottom: 0;
        }
        &.radio-btn {
          margin-bottom: 12px;
        }
      }
    }
    .layout--right--main();
    .padding48-40();
    .cate_card {
      margin-bottom: 30px;
      .cate_name {
        line-height: 40px;
        font-weight: 600;
        font-size: 16px;
        color: #333;
      }
    }
  }

  .page-padding {
    padding: 0 0;
  }
  .h1__title {
    margin-bottom: 32px;
  }
  .div__view {
    margin-bottom: 12px;
  }
  .div__func {
    min-height: 190px;
    background: @background_white;
    border-radius: 4px;
    margin: 0 auto 40px auto;
  }
  .div__view__title {
    margin-left: 24px;
    font-size: @font_size_16;
    font-weight: 400;
    color: @font_color_h1;
    line-height: 22px;
  }
  .switch__list {
    li {
      display: block;
      list-style-type: none;
      margin-bottom: 12px;
      line-height: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .leve3_title {
    display: inline-block;
    text-align: right;
    min-width: 88px;
    &.title--999 {
      margin-left: 8px;
    }
  }

  .saasicon_help_m {
    color: #999999;
  }
</style>
